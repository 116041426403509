import React, { useEffect, useState, useRef } from 'react'
import { connect } from "react-redux";

import HomePageRender from "./HomePageRender";

import {
    SET_MODULE_SELECTED,
    SHOW_INFO_MSG_HOME,
    SET_NOTIFICATIONS,
} from "../../redux/actions/appData/index";

import {
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_SYNC,
    CLEAR_GET_OVERVIEW_DATA,
} from "../../redux/actions/overView/index";

import { GET_READERS_SUMMARY_CARDS } from "../../redux/actions/readersSummary/index";


function HomePage(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
        // props.gstate.moduleSelected && props.GET_READERS_SUMMARY_CARDS(props.gstate.moduleSelected);
    }, [props.gstate.moduleSelected])

    const isCum = localStorage.getItem('isCum')

    // useEffect(() => {
    //     //get overview data every time isCum changes and this if to not send the API 2 times with old idCum value and new one
    //     if(isCum && (JSON.parse(isCum) == props.gstate.isComultive)) {
    //         props.GET_OVERVIEW_DATA(props.gstate.isComultive, props.GET_OVERVIEW_DATA_SYNC, props.CLEAR_GET_OVERVIEW_DATA, props.GET_OVERVIEW_DATA)
    //     }
    // }, [props.gstate.isComultive])

    useEffect(() => {
        if (isCum) {
            props.GET_OVERVIEW_DATA(JSON.parse(isCum), props.GET_OVERVIEW_DATA_SYNC, props.CLEAR_GET_OVERVIEW_DATA, props.GET_OVERVIEW_DATA)
        } else {
            props.GET_OVERVIEW_DATA(props.gstate.isComultive, props.GET_OVERVIEW_DATA_SYNC, props.CLEAR_GET_OVERVIEW_DATA, props.GET_OVERVIEW_DATA)
        }
    }, [props.gstate.isComultive])

    //get list of all modules
    const [modules, setModules] = useState()

    useEffect(() => {
        if (props.gstate.overViewData && props.gstate.daySelected?.value) {
            //get the modules names from overview API response data and set "modules" state with this array
            setModules(Object.getOwnPropertyNames(props.gstate.overViewData[props.gstate.daySelected.value]))
        }
    }, [props.gstate.daySelected.value, props.gstate.overViewData])

    //always select the first module as a default selection
    useEffect(() => {
        if (modules && !props.gstate.moduleSelected) {
            props.SET_MODULE_SELECTED(modules[0])
        }
    }, [modules])

    //store the data of the module at the module data selected state

    const [moduleDataSelected, setModuleDataSelected] = useState()

    const [monthlyData, setMonthlyData] = useState()

    useEffect(() => {
        if (props.gstate.moduleSelected && props.gstate.overViewData && props.gstate.overViewData[props.gstate.daySelected.value][props.gstate.moduleSelected]) {
            setModuleDataSelected(props.gstate.overViewData[props.gstate.daySelected.value][props.gstate.moduleSelected])
        } else {
            setModuleDataSelected(null)
        }

        if (props.gstate.moduleSelected && props.gstate.overViewData?.monthly_data_today && props.gstate.overViewData?.monthly_data_yesterday && props.gstate.daySelected.value) {
            if(props.gstate.daySelected.value == 'Today') {
                setMonthlyData(props.gstate.overViewData.monthly_data_today[props.gstate.moduleSelected])
            } else {
                setMonthlyData(props.gstate.overViewData.monthly_data_yesterday[props.gstate.moduleSelected])
            }
        }
    }, [props.gstate.moduleSelected, props.gstate.daySelected, props.gstate.overViewData])

    // //add notification data 
    // useEffect(() => {
    //     if (props.gstate.overViewData) {
    //         //get notification array using generateNotifications created at helpers folder
    //         let notificationsBody = generateNotifications(props.gstate.overViewData)
    //         //set notificationData reducer with the output notification
    //         props.SET_NOTIFICATIONS(notificationsBody)
    //         //save the notification to the localstorage so that user can see the notification even if he reload at other page not the home page
    //         localStorage.setItem("notifications", JSON.stringify(notificationsBody));
    //     }
    // }, [props.gstate.overViewData])

    const runningRef = useRef(null);
    const monthlyRef = useRef(null);

    return (
        <div style={{ position: 'relative' }}>
            {props.gstate.moduleSelected == 'Broilers' && monthlyData && (
                <div className='switch-month-day-data'>
                    <b onClick={() => runningRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                        Running
                    </b>
                    <b onClick={() => monthlyRef.current?.scrollIntoView({ behavior: 'smooth' })}>
                        Monthly
                    </b>
                </div>
            )}

            <div ref={runningRef}>
                <HomePageRender
                    moduleDataSelected={moduleDataSelected}
                    modules={modules}
                />
            </div>

            {props.gstate.moduleSelected == 'Broilers' && monthlyData && (
                <div ref={monthlyRef}>
                    <div className='monthly-header'>
                        <h1 className='general-container'>Month to Date</h1>
                    </div>
                    <div>
                        <HomePageRender
                            moduleDataSelected={monthlyData}
                            modules={modules}
                            monthly
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    SET_MODULE_SELECTED,
    GET_OVERVIEW_DATA,
    GET_OVERVIEW_DATA_SYNC,
    CLEAR_GET_OVERVIEW_DATA,
    SHOW_INFO_MSG_HOME,
    SET_NOTIFICATIONS,
    GET_READERS_SUMMARY_CARDS,
})(React.memo(HomePage));