import React, { useEffect, useState } from 'react'
import { Fragment } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import SecureLS from "secure-ls";
import { useHistory } from "react-router-dom";

import { ReactComponent as AbuErdanLogoSvg } from "../../assets/svg-modules-icon/logo.svg";
import Spinner from '../../layouts/spinners/Spinner';

import {
    START_TOUR_POPUP,
} from "../../redux/actions/appData/index"

import {
    LOGIN,
} from "../../redux/actions/registration/index"

function LoginPage(props) {

    //organization URL input field state and handle change function
    const [URL, setURL] = useState(".abuerdan.com")

    const uRLchangeHandler = (e) => {
        setURL(e.target.value)
    }

    //this is to place cursor befor ".abuerdan.com" to start typing his organization name directly 
    const focusURLfield = () => {
        var end = document.getElementById("org-url")
        var len = end.value.length;

        // Mostly for Web Browsers
        if (end.setSelectionRange) {
            // end.focus();
            setTimeout(() => {
                if (URL.length <= 13) {
                    end.setSelectionRange(len, 0);
                } else {
                    end.setSelectionRange(len, URL.length - 13);
                }
            }, 0);
        }
    }

    //if user entered the organization URL one befor so show him this URL again 
    // so I am getting this URL from Local storage name "userUrl" and setURL with this value
    //for sure he can edit this URL if he like
    const userURLfromLocal = localStorage.getItem('userUrl')

    useEffect(() => {
        if (userURLfromLocal) {
            setURL(userURLfromLocal)
        }
    }, [])

    //email input field state and handle change function
    const [email, setEmail] = useState("")

    const emailChangeHandler = (e) => {
        setEmail(e.target.value)
    }

    //password input field state and handle change function
    const [password, setPassword] = useState("")

    const passwordChangeHandler = (e) => {
        setPassword(e.target.value)
    }


    //spinner handler
    const [isLoading, setIsLoading] = useState(false)

    //get history using useHistory Hook to push the rout if he Signed in succefully
    const history = useHistory();

    //these 3 states to handle validations
    const [emailIncorrect, setEmailIncorrect] = useState(false)

    const [pwIncorrect, setPwIncorrect] = useState(false)

    const [errorMsg, setErrorMsg] = useState(false)


    //handle submit login
    const handleSubmit = (e) => {
        e.preventDefault();
        //start the spining
        setIsLoading(true);
        //reset the validations states
        setEmailIncorrect(false);
        setPwIncorrect(false);
        setErrorMsg(false)

        //save the organization URL to the local storage with name "userUrl"
        localStorage.setItem("userUrl", URL);

        const body = {
            email: email,
            password: password,
        };


        if (URL) {
            //here we are going to use the URL user entered before and send user's email and password to login
            axios.post(`https://${URL}/api/executive_app/login/`, body)
                .then(res => {
                    if (res?.data?.token) {
                        setTimeout(() => {
                            props.LOGIN(true);
                            setIsLoading(false);
                            history.push("/");
                            //this START_TOUR_POPUP action used once only after login to show start tour popup only once
                            props.START_TOUR_POPUP(true);
                        }, 500);
                    }
                    //I am using SecureLS to encrypt the user Token before saving it in the local storage with name "UT"
                    // SecureLS needs a encryptionSecret and you can type any password but keep in mind that you will use the same password in decrepting
                    var ls = new SecureLS({ encodingType: 'des', isCompression: true, encryptionSecret: '$_!exEcultive-aPp@data*2021.aBuerdAn$$' });
                    //encrypt user token and save it to local storage
                    ls.set('UT', { data: res.data.token });
                }).catch((error) => {
                    //if something went wrong first stop the loading spinner
                    setIsLoading(false)
                    //then validate what is wrong with the login response by checking respons codes
                    if (error.response) {
                        //404 then email user entered is incorect ** 400 password user entered is incorect ** 403 mean no permission to this user to use the executive app
                        if (error.response.status === 404) {
                            setEmailIncorrect(true)
                        } else if (error.response.status === 400) {
                            setPwIncorrect(true)
                        } else if (error.response.status === 403) {
                            setErrorMsg(error.response?.data?.email)
                        } else if (error.response.status === 500) {
                            setErrorMsg("Something went wrong")
                        }
                    } else {
                        //if response code is something else then maybe interent connection problem or URL user entered is wrong
                        setErrorMsg("server unreachable")
                    }
                });
        }
    }

    return (
        <div className="login-page">
            <div className="login-page__start">
                <div className="login-page__start__logo">
                    <AbuErdanLogoSvg />
                </div>
                <div className="login-page__start__line" />
                <div className="login-page__start__title">
                    AbuErdan Executive App
                </div>
                <div className="login-page__start__desc">

                </div>
            </div>
            <form onSubmit={handleSubmit} className="login-page__end">
                <div className="login-page__end__head">
                    <div className="login-page__end__head__logo">
                        <AbuErdanLogoSvg />
                    </div>
                    <div className="login-page__end__head__name">
                        AbuErdan Executive App
                    </div>
                    <div className="login-page__end__head__line" />
                </div>
                {!isLoading ? (
                    <Fragment>
                        <div className="login-page__end__inputs">
                            <div className="login-page__end__inputs__field">
                                <div className="login-page__end__inputs__field__title">Organization URL</div>
                                <div className="login-page__end__inputs__field__group">
                                    <input type="text" className="login-page__end__inputs__field__group__input" placeholder="Organization URL" onFocus={focusURLfield}
                                        id="org-url" required onChange={uRLchangeHandler} value={URL} />
                                    <label htmlFor="org-url" className="login-page__end__inputs__field__group__label">Organization URL</label>
                                </div>
                            </div>
                            <div className="login-page__end__inputs__field">
                                <div className="login-page__end__inputs__field__title">Email</div>
                                <div className="login-page__end__inputs__field__group">
                                    <input type="email" className="login-page__end__inputs__field__group__input" placeholder="Please enter your email"
                                        id="login-email" required onChange={emailChangeHandler} value={email} style={emailIncorrect ? { border: "2px solid red" } : {}} />
                                    <label htmlFor="login-email" className="login-page__end__inputs__field__group__label">Email</label>
                                </div>
                                {emailIncorrect && <span>email you entered is incorrect</span>}
                            </div>
                            <div className="login-page__end__inputs__field">
                                <div className="login-page__end__inputs__field__title">Password</div>
                                <div className="login-page__end__inputs__field__group">
                                    <input type="password" className="login-page__end__inputs__field__group__input" placeholder="Please enter your password"
                                        id="last-name" required onChange={passwordChangeHandler} value={password} style={pwIncorrect ? { border: "2px solid red" } : {}} />
                                    <label htmlFor="last-name" className="login-page__end__inputs__field__group__label">Password</label>
                                </div>
                                {pwIncorrect && <span>Password you entered is incorrect</span>}
                            </div>
                            {errorMsg && <div className="login-page__end__inputs__fail">{errorMsg}</div>}
                        </div>
                        <div className="login-page__btn">
                            <button type="submit" className="btn btn__login">Sign in</button>
                        </div>
                    </Fragment>
                ) : <Spinner />}
            </form>
        </div>
    )
}

const mapStateToProps = (state) => {
    return { gstate: state };
};

export default connect(mapStateToProps, {
    LOGIN,
    START_TOUR_POPUP,
})(LoginPage);